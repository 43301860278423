import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'

import PrivacyPage from '../components/PrivacyPage'
import i18n from '../../config/i18n'

const Privacy = ({ data: { privacy }, pageContext: { locale }, location }) => {
  const { data } = privacy

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} title={i18n[locale].seo.privacy.title} />
      <PrivacyPage data={data} />
    </>
  )
}

export default Privacy

Privacy.propTypes = {
  data: PropTypes.shape({
    privacy: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query privacyQuery($locale: String!) {
    privacy: prismicPrivacyPolicy(lang: { eq: $locale }) {
      data {
        main_title
        effective_date
        content {
          html
        }
      }
    }
  }
`
