import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import * as S from './styled'
import { LocaleContext } from '../Layout'
import bubbles from '../../../content/images/bubbles.svg'

const PrivacyPage = ({ data }) => {
  const { main_title: title, effective_date: date, content } = data
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.TopWrapper>
          <S.Col>
            <S.Title>{title}</S.Title>
            <S.Label>
              {i18n.effectiveDate} - {moment(date).format('MM/DD/YYYY')}
            </S.Label>
          </S.Col>
          <S.Bubbles src={bubbles} alt="Contacts" />
        </S.TopWrapper>
        <S.Row>
          <S.Text dangerouslySetInnerHTML={{ __html: content.html }} />
        </S.Row>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
}
export default PrivacyPage
